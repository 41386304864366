import cx from "classnames"
import React, { useContext } from "react"
import { Trans, useTranslation } from "react-i18next"

import { AffiliateContext } from "../../contexts/AffiliateContext"
import { useSmoothScroll } from "../../hooks/useSmoothScroll"
import { Button2 } from "../button2"
import { ContactFormNew } from "../forms/contact-form-new"
import one from "./1.svg"
import two from "./2.svg"
import ava from "./ava.png"
import ellipse from "./ellipse.svg"
import marker from "./marker.svg"
import styles from "./nutritionist.module.css"

export const NutritionistSection = ({ id }) => {
  const scroll = useSmoothScroll()
  const { t } = useTranslation()

  const openOrderForm = selector => {
    scroll.animateScroll(document.getElementById(selector))
  }

  const affiliateContext = useContext(AffiliateContext)
  const { isAffiliate } = affiliateContext.affiliate

  return (
    <section className={styles.nutritionistWrap} id={id}>
      <h3 className="fancyUnderlineText fancyUnderlineText--orange sectionTitleNew text-center">
        <Trans i18nKey="nutritionist.title">
          Vždy <span>pod dohledem</span> odborníka na výživu
        </Trans>
      </h3>

      <div className={styles.nutritionist}>
        <div className={styles.nutritionistImg}>
          <div className={styles.avatar}>
            <img className={styles.avatarImg} src={ava} alt="avatar" />
            <div className={styles.avatarInfo}>
              <div className={styles.avatarInfoName}>
                <span>Jitka Dokoupilová</span>
              </div>
              <div className={styles.avatarInfoPosition}>
                <img src={marker} alt="marker" />
                <span>Expert nutritionist</span>
                <img src={marker} alt="marker" />
              </div>
            </div>
          </div>
          <img
            className={styles.nutritionistImgEllipse}
            src={ellipse}
            alt="img"
          />
        </div>
        <div className={styles.nutritionistInfo}>
          <div className={styles.nutritionistInfoList}>
            <p className={styles.nutritionistInfoListItem}>
              <Trans i18nKey="nutritionist.text1">
                Jsem <span className={styles.textBold}>nutriční poradce</span> v
                NutritionPro a výživou prostě žiju! Vystudovala jsem analýzu
                potravin na VŠCHT a nutriční terapii na 1. lékařské fakultě.
                Klientům radím už více{" "}
                <span className={styles.textBold}>než 15 let</span> a za tu dobu
                mi jich rukama prošlo přes{" "}
              </Trans>
              <span className={styles.textBold}>1500</span>.
            </p>
            <p className={styles.nutritionistInfoListItem}>
              <Trans i18nKey="nutritionist.text2Blueghost">
                Máte krabičky, ale nevíte, jak jíst o víkendu? Váháte, jaký
                pitný režim se ke krabičkování hodí? Chcete se ujistit, jaký
                příjem energie potřebujete? Máte jiné výživové otázky nebo
                chcete teprve začít? Pokud něco nevíte, jsem tu pro Vás. A
                budete mít jasno.
              </Trans>
            </p>
          </div>
          <div className={styles.nutritionistForm}>
            <div className={cx(styles.discountInfo, styles.formWrap)}>
              <ContactFormNew
                form_name={"konzultace-dietolog"}
                themeLight={true}
                horizontal={true}
                btnText={t("forms.contactFormNewCTABlueghost")}
              />
            </div>
          </div>
          <div className={styles.nutritionistPrompt}>
            <span className={styles.nutritionistPromptOrder}>
              <span className={styles.textBold}>
                {t("nutritionist.promptOrderText1")}
              </span>{" "}
              {t("nutritionist.promptOrderText2")}
              <br></br>
              <span className={styles.textBold}>
                {t("nutritionist.promptOrderText3")}
              </span>{" "}
              {t("nutritionist.promptOrderText4")}
            </span>
            <div className={styles.nutritionistPromptImg}>
              <img className={styles.imgOne} src={one} alt="img" />
              <img src={two} alt="img" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
