import cx from "classnames"
import React, { useCallback, useEffect, useState } from "react"
import Countdown from "react-countdown"

import { getUTM } from "../../helpers"
import { SaleForm } from "../forms/sale-form"
import formStyles from "../forms/sale-form.module.css"
import { useModal } from "../modal2"
import styles from "../modal2/modal.module.css"
import saleFormStyles from "./sale-form-modal.module.css"

const EXPIRE_PERIOD = 24 * 60 * 60 * 2 * 1000

const Completionist = () => <span>Your discount expired!</span>

// Renderer callback with condition
const renderer = ({ days, hours, minutes, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />
  } else {
    // Render a countdown
    return (
      <span className={saleFormStyles.countdownNumbers}>
        <span className={saleFormStyles.countdownNumber}>{days}</span>d
        <span className={saleFormStyles.countdownTimeSeparator}>:</span>
        <span className={saleFormStyles.countdownNumber}>{hours}</span>h
        <span className={saleFormStyles.countdownTimeSeparator}>:</span>
        <span className={saleFormStyles.countdownNumber}>{minutes}</span>m
      </span>
    )
  }
}

const FormCountdown = () => {
  const [countdownTimestamp, setCountdownTimestamp] = useState(() => {
    return new Date().getTime() + EXPIRE_PERIOD - new Date().getTime()
  })

  const keyWord = "sleva"
  const UTMS = getUTM()
  const hasKeyWord =
    UTMS && Object.values(UTMS).some(value => value && value.includes(keyWord))

  const handleSetCountdown = useCallback(() => {
    const countdown = localStorage.getItem("countdown")
    if (countdown) {
      const countdownValue = JSON.parse(countdown)
      const diff = new Date().getTime() - countdownValue.time
      // console.log("DIFF", diff);
      if (diff >= countdownValue.expire) {
        localStorage.setItem("salesModalAvailable", 0) // make modal unavailable if expired
        // console.log("Expired!")
      } else {
        setCountdownTimestamp(countdownValue.expire - diff)
      }
    } else {
      const obj = {
        time: new Date().getTime(),
        expire: EXPIRE_PERIOD,
      }
      const objStr = JSON.stringify(obj)
      hasKeyWord && localStorage.setItem("countdown", objStr)
      localStorage.setItem("salesModalAvailable", 1)
    }
  }, [hasKeyWord])

  useEffect(() => {
    handleSetCountdown()
  }, [handleSetCountdown])

  return (
    <div className={saleFormStyles.countdown}>
      <h5 className={saleFormStyles.countdownTitle}>Sleva platí:</h5>
      {countdownTimestamp !== 0 && (
        <Countdown date={Date.now() + countdownTimestamp} renderer={renderer} />
      )}
    </div>
  )
}

const SaleModalForm = ({ formName, discount }) => (
  <>
    <div className={saleFormStyles.modalImg} />
    <h3
      className={cx(
        "text-center",
        styles.modalHeaderTitle,
        formStyles.modalHeaderTitle
      )}
    >
      Máme pro Vás
      <b>slevu {discount ? discount : 1000} Kč</b>
      na první objednávku
    </h3>
    <SaleForm form_name={formName} discount={discount} btnText="Odeslat" />
    <FormCountdown />
  </>
)

export const SaleFormModal = ({ formName }) => {
  const { show, RenderModal } = useModal()
  const [isCountdownModal, setIsCountdownModal] = useState(true)
  const [showModalCTA, setShowModalCTA] = useState(true)
  const [discount, setDiscount] = useState(null)

  const handleModalAvailability = () => {
    setIsCountdownModal(prev => !prev)
    localStorage.setItem("salesModalAvailable", 0)
  }

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    const keyWord = "sleva"
    const UTMS = getUTM()
    const hasKeyWord =
      UTMS &&
      Object.values(UTMS).some(value => value && value.includes(keyWord))
    const countdown = localStorage.getItem("countdown")
    const isAvailable =
      countdown && !!JSON.parse(localStorage.getItem("salesModalAvailable"))
    hasKeyWord &&
      !countdown &&
      show() &&
      localStorage.setItem("salesModalAvailable", 1)
    hasKeyWord &&
      countdown &&
      !isAvailable &&
      setShowModalCTA(true) &&
      localStorage.setItem("salesModalAvailable", 1)
    !hasKeyWord && setShowModalCTA(isAvailable)
  }, [show])

  useEffect(() => {
    // const UTMS = getUTM();
    let urlString = document.location.href
    let url = new URL(urlString)
    const keyWord = "sleva"
    const UTMS = getUTM()
    const hasKeyWord =
      UTMS &&
      Object.values(UTMS).some(value => value && value.includes(keyWord))
    const discountValueInParam = url.searchParams.get("sale")
    const discountValue = discountValueInParam || "1000"
    const discountValueStorage = localStorage.getItem("discount")
    if (discountValue) {
      setDiscount(discountValue)
    } else if (discountValueStorage) {
      setDiscount(discountValueStorage)
    }
    hasKeyWord &&
      discountValue &&
      localStorage.setItem("discount", discountValue)
    // discountValue && localStorage.setItem("salesModalAvailable", 1); // TODO: check in more ccorrect way
    hasKeyWord && discountValue && setShowModalCTA(true)
    const isModalAvailable = !!JSON.parse(
      localStorage.getItem("salesModalAvailable")
    )
    isModalAvailable &&
      window.dataLayer.push({
        event: "ga.pageview",
        pageURL: `/formular-zajem-o-slevu${discount}`,
        pageTitle: "Formulář zájem o slevu",
        pageType: "Form-3",
      })
  }, [discount, show])

  useEffect(() => {
    let userVisitCount = JSON.parse(localStorage.getItem("userVisitCount"))
    userVisitCount = userVisitCount ? userVisitCount + 1 : 1
    localStorage.setItem("userVisitCount", userVisitCount)
    if (userVisitCount >= 3) {
      setDiscount("500")
      localStorage.setItem("discount", "500")
    }
  }, [])

  return (
    <>
      {isCountdownModal && (
        <>
          <RenderModal className="modalForm">
            <SaleModalForm
              formName={formName}
              handleModalAvailability={handleModalAvailability}
              discount={discount}
            />
          </RenderModal>
          {showModalCTA && (
            <button className={saleFormStyles.openModalBtn} onClick={show}>
              <span>{discount ? `Sleva ${discount} kč` : "Sleva 1000 kč"}</span>
            </button>
          )}
        </>
      )}
    </>
  )
}
