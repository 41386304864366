import React from "react"
import { AdvantageCard } from "./advantage-card"
import styles from "./advantages.module.css"
import icon1 from "./icons/icon1.svg"
import icon2 from "./icons/icon2.svg"
import icon3 from "./icons/icon3.svg"
import icon4 from "./icons/icon4.svg"

export const Advantages = ({ id }) => {
  return (
    <section id={id} className={styles.advantagesView}>
      <div className={styles.advantages}>
        <div className={styles.advantagesWrapper}>
          <AdvantageCard
            imgSrc={icon1}
            accent={"Nejpřesnější"}
            title={"čas doručení"}
            description={
              "Doručujeme v dvouhodinových časových rozmezích, které si můžeš libovolně měnit. Jídlo ti dovezeme až pod nos v čas, který si určíš."
            }
          />
          <AdvantageCard
            imgSrc={icon2}
            accent={"Nejflexibilnější"}
            title={"doprava zdarma"}
            description={"Nečekej celý večer doma na kurýra. Čas i místo doručení si můžeš flexibilně upravovat takřka do poslední chvíle. Jednoduše zavolej nebo napiš."}
          />
          <AdvantageCard
            imgSrc={icon3}
            accent={"U nás chutná"}
            title={"95 % klientům"}
            description={
              "95 % zákazníků hodnotí chuť našich jídel 5 hvězdičkami. Aktivně sbíráme zpětnou vazbu na každé jídlo a menu neustále vylepšujeme."
            }
          />
          <AdvantageCard
            imgSrc={icon4}
            accent={"Profesionální"}
            title={"konzultace v ceně"}
            description={
              "Od prvního dne jsi na správné cestě. S tvými stravovacími cíli ti budou zdarma pomáhat naši výživoví konzultanti a nutriční expertka."
            }
          />
        </div>
      </div>
    </section>
  )
}
