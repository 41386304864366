import cx from "classnames"
import React from "react"
import { Trans, useTranslation } from "react-i18next"
import Slider from "react-slick"

import { AnimatedWrapper } from "../animated-wrapper"
import { Button2 } from "../button2"
import { Container } from "../container"
import { LocalizedLink } from "../localized-link"
import { ReviewCard } from "../review/card"
import img1 from "./images/review1.svg"
import img2 from "./images/review2.svg"
import img3 from "./images/review3.svg"
import img4 from "./images/review4.svg"
import styles from "./reviews.module.css"
import nutrition from "../../../static/images/review-icons/nutrition.svg"
import google from "../../../static/images/review-icons/google.svg"
import fb from "../../../static/images/review-icons/facebook.svg"

// import img5 from './images/review5.jpg';
const ReviewsCarousel = () => {
  const settings = {
    arrows: true,
    infinite: true,
    // fade: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  }
  return (
    <Slider
      {...settings}
      className={cx("reviews-carousel", styles.carouselList)}
    >
      {/* <div className={styles.carouselItem}>
        <img src={img4} className={styles.carouselItemImg} alt="img" />
      </div>
      <div className={styles.carouselItem}>
        <img src={img1} className={styles.carouselItemImg} alt="img" />
      </div>
      <div className={styles.carouselItem}>
        <img src={img2} className={styles.carouselItemImg} alt="img" />
      </div>
      <div className={styles.carouselItem}>
        <img src={img3} className={styles.carouselItemImg} alt="img" />
      </div> */}
      {/* <div className={styles.carouselItem}>
        <img src={img5} className={styles.carouselItemImg} alt="img" />
      </div> */}
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Adam Pechar"
          date="20.04.2021"
          text="Jedna z nejlepších investic, kterou jsem za poslední dobu učinil, mohu jenom doporučit!
          Bezproblémová spolupráce, vyvážená strava a skvělá chuť. Nejlepší krabičková dieta, kterou jsem doposud vyzkoušel. Po jídle se cítím skvěle a pokaždé se těším na další chod. Zásadní krok pro zdravý životní styl. 👍
          Jedním slovem PERFEKTNÍ"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Renata Vydrová"
          date="20.04.2021"
          text="Jídlo mají moc dobré. :) A navíc už nemusím řešit, co budu jaký den vařit. Za mě je to TOP!"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Anton Lancevič"
          date="20.04.2021"
          text="Mohu jen doporucit!"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Hana Císlerová"
          date="20.04.2021"
          text="Nejlepší krabičky, co jsem kdy zkoušela. Jídla bylo navíc dost, tak jsem se podělila i se synem a vyšlo nám to laciněji, než nakupovat a vařit"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Margita Novotná"
          date="20.04.2021"
          text="Porce víc než dostatečné, velmi rozmanité, chutné. Servis perfektní, domluva i další doporučení s ochotnou poradkyní bez chybičky. Doporučuji."
        />
      </div>
      {/* <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Kuba Marlicki (Marlon)"
          date="20.04.2021"
          text="After almost a month I would like to recommend NutritionPro to everyone who want to lose weight. Everything from the very beginning was 100% professional. For now I can say that every meal I got was tasty and what is even more important their diet works. I'm losing weight. Of course apart from diet I also do some exercises and run, but without them It wouldn't be so easy. Special thanks to the delivery guy. He's always nice, always helpful and informing about any possible delays. Best box diet I have ever ordered and your competitors in Prague soon will have a problem If you will keep doing such a good job :)"
        />
      </div> */}
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Kristýna Burdová"
          date="20.04.2021"
          text="Krabičky jsou naprosto super, zachraňovaly mě během home officů, kdy jsem vůbec neměla čas si uvařit, natož něco zdravého. Salesačka Jitka mi pomohla naplánovat optimální kalorický objem tak, abych neměla hlad a zároveň trochu zhubla. Byla jsem moc mile překvapená, že se mě Jitka vyptala na moje stravovací a pohybové návyky a podle toho sestavila krabičky, které jsou velmi chutné. Doporučuju!"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Jan Vítek"
          date="20.04.2021"
          text="The fish with the veggies was amazing! 👌"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Jan Kříž"
          date="20.04.2021"
          text="Vždy výborná chuť i vzhled, přesně pro mě na míru, skvělý servis okolo, pro mě dokonalá služba a jsem konečně nakrmený tak, abych nemusel řešit dojídání kdekoli jinde.
          Navíc během covid stavu NutritionPro vyřešilo i situaci lidem kolem mě, kteří můžou zůstat a jíst doma, když se necítí bezpečně shánět a nakupovat cokoli venku.
          Takže palec hore a nejen pro letošní rok jasná volba. Díky za ní!!"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Lev Lyapeikov"
          date="20.04.2021"
          text="Great service! I have ordered twice for the stock. Couriers arrived on time, food quality is excellent. My wife at home with a 6 month old baby and there is no time for cooking. A diet of 2000 calories is enough for my wife all day and I take lunch with me for work. A very profitable solution, cheaper than buying products in the store + saving time. Today I made an order for 12 days (Pleasantly surprised by the low prices), for almost two weeks I’m free from shopping in groceries. Thank you so much for the yummy food and services!"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Nicole Ponce"
          date="20.04.2021"
          text="For someone that is a very picky eater, I would have to say that the meals from NutritionPro have exceeded my expectations and more. It makes my day so much easier to know that I have my meals prepared and ready everyday, straight to my door. I would highly recommend."
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Aneta Benova"
          date="20.04.2021"
          text="Velmi chutné a pestré jídlo :)"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Antoine Bourg"
          date="20.04.2021"
          text="Super satisfied, the recipes are simple yet diverse, and delightful. Feels like home cooking, and the results are seen very early on. Would recommend it to anyone looking for a tailored nutrition plan!!"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Polina Moroz"
          date="20.04.2021"
          text="(Původní) Мне очень понравилось. Меню разнообразное и ,самое главное, вкусное. Советую!

          (Překlad) Opravdu se mi to líbilo. Nabídka je pestrá a hlavně chutná. Doporučuji!"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Ondřej Staněk"
          date="20.04.2021"
          text="Výborné krabičky. Moc se neopakují a často člověk ochutná i exotické potraviny, na které by normálně nenarazil. Porce skvělé a vyvážené. Hlad rozhodně nemám a přitom přirozeně pomaličku hubnu"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Tereza Žatečková"
          date="20.04.2021"
          text="Krabičky odebírám už delší dobu a stále si drží svou kvalitu. Jídla jsou pestrá, čerstvá a dovážka vždy přesná. Poměr kvalita/cena odpovídá. Doporučuji👍"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Viktor Nyitray"
          date="20.04.2021"
          text="S krabičkami jsem hodně spokojený. Nejsou to ty klasické jídla z vývařovny zabalené do krabiček, ale to jídlo je opravdu chutné! Doporučuji alespoň vyzkoušet :)"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Irena Rabochova"
          date="20.04.2021"
          text="Jídlo je velmi chutné a vyvážené, z čerstvých surovin. Úspora času a úbytek váhy. Servis je prvotřídní, zejména rozvoz."
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Lucie Bartošová"
          date="20.04.2021"
          text="Jídlo je naprosto skvělé, ale je tu ještě jedna věc o dost lepší, to jsou dovozci jídla, převážně jedna paní, vždy mi zlepší den, je naprosto dokonalá"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Tereza Hofmanová"
          date="20.04.2021"
          text="Velice dobré krabičky, jídla mi vyhovují svou pestrostí. Pro mě jasná doba v období, kdy nestíhám vařit. DOPORUČUJI!"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Zdenka Svobodova"
          date="20.04.2021"
          text="Moc chutné jídlo, velmi rozmanité na druhy surovin. A ochutnáte i nevšední jidlo.
          Kombinace mi někdy přišla zvláštní (čočka s rybou), ale vždy opravdu dobře připravené a i přes prvotní zvláštnost to ladí chuťově k sobě.
          Možnost si vybrat ze sladkých či slaných svačin. Mám sladkou ;) a plně uspokojí mlsouny co chtějí i nějaké kilčo shodit.
          Ze začátku jsem vnitřně bojovala s malými porcemi, ale to bylo mou předchozí rozežraností. Za 3 dny jsem si zvykla a po měsíci mohu zhodnotit, že porce jsou akorát, pestré a hlavně mi chutnají. Nemám vlastně pocit, že držím dietu ;) a na jídlo se těším.
          Rozvoz je také super, 3x týdně i v pozdních hodinách a paní co jezdí ke mně, je moc milá
          Děkuji a jen tak dál ;)"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Linda Dlabačová"
          date="20.04.2021"
          text="Nový jídelníček ✌️"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Maldonado David"
          date="20.04.2021"
          text="Super chutné jídlo"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={nutrition}
          name="Matěj Schultz"
          date="20.04.2021"
          text="Nejlepší poměr cena/kvalita, neskutečně jste mi pomohli, moc děkuji!!"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={nutrition}
          name="Klára Onderková"
          date="20.04.2021"
          text="Vždy dovoz včas a supr servis, díky za ušetřený čas nutrition pro!!"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={nutrition}
          name="Aneta pecková"
          date="20.04.2021"
          text="možná první podobný servis, který mi během prvního týdnu nezklamal, dokonce naopak, paráda!!"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={nutrition}
          name="Gregory Pelec"
          date="20.04.2021"
          text="Its been a while since I started my plan with you and surpisingly every meal was very delicious and the kilos went down finally!! Highly recommend The Home office pack to those who spend everyday in the office as I do "
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={nutrition}
          name="Jitka Beranová"
          date="20.04.2021"
          text="Blíží se ke konci první měsíc co jsem s Vámi a už se citím tísickrát lépe, minus 3 kila a konečně mám čas a energii, jste naprosto Boží"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={fb}
          name="Jan Kříž"
          date="20.04.2021"
          text="Vždy výborná chuť i vzhled, přesně pro mě na míru, skvělý servis okolo, pro mě dokonalá služba a jsem konečně nakrmený tak, abych nemusel řešit dojídání kdekoli jinde.
          Navíc během covid stavu NutritionPro vyřešilo i situaci lidem kolem mě, kteří můžou zůstat a jíst doma, když se necítí bezpečně shánět a nakupovat venku.
          Takže palec hore a nejen pro letošní rok jasná volba. Díky za ní!!"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={fb}
          name="Eldar Alizade"
          date="20.04.2021"
          text="Jidlo chutna skvele, netrpim hlady, a hlavne jsem za mesic zhubnul o par kilo! Coz byl hlavni cil. Navic i konzultanti jsou ochotni a komunikace je vyborna. Takze vsechno super, doporucuji!"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={fb}
          name="Káčko Béčko"
          date="20.04.2021"
          text="Milé překvapení, obrovská spokojenost (můžu srovnávat i s konkurencí). Jídla dle mého gusta, objednala jsem znovu."
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={fb}
          name="Patrick Beznoska"
          date="20.04.2021"
          text="Skvělá služba a jídlo také"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={fb}
          name="Alan Pock"
          date="20.04.2021"
          text="To je pecka, po měsíci se mi to fakt líbí, asi nejvíce to, že nemusím řešit a pořád vymýšlet co si dám."
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={fb}
          name="Pavel Jiranek"
          date="20.04.2021"
          text="Tasty, quick and neat solution for your daily eating habits!"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={fb}
          name="Petra Dolanská"
          date="20.04.2021"
          text="Luxusni jidlo, super komunikace a porce akorát. Musím jenom DOPORUČIT"
        />
      </div>
    </Slider>
  )
}

export const Reviews2 = ({ id = "" }) => {
  const { t } = useTranslation()
  return (
    <section className={styles.section} id={id}>
      <Container isWide={true} className={styles.container}>
        {/* <AnimatedWrapper> */}
        <h3 className="fancyUnderlineText sectionTitleNew text-center">
          <Trans i18nKey="home.reviews.title">
            Co <span>o nás říkají</span> naši zákazníci?
          </Trans>
        </h3>
        <ReviewsCarousel />
        <div className={cx("text-center", styles.btnWrapper)}>
          <Button2 color="outline">
            <a
              style={{ color: "inherit", textDecoration: "none" }}
              href={"reviews"}
            >
              {t("home.reviews2.btnText")}
            </a>
          </Button2>
        </div>
        {/* </AnimatedWrapper> */}
      </Container>
    </section>
  )
}
